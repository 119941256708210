<template>
  <div class="sm:m-4 sm:px-4">
    <div v-if="$route.params.hash" class="p-4 w-full flex items-center">
      <div
        v-if="logs && logs.data && logs.data.length > 0 && logs.data[0].email"
        class="text-xl font-bold"
      >
        Viewing logs for email:
        <router-link
          :to="`/email/${$route.params.hash}`"
          class="ml-1"
          v-text="logs.data[0].email.subject || '(no subject)'"
        />
      </div>
      <div v-else class="block">
        <router-link
          to="/email/logs"
          @click.native="$store.commit('logs/setView', 'all')"
        >
          Click here for logs of all emails
        </router-link>
      </div>
      <div class="flex-1" />
      <mdc-icon
        class="ml-auto text-error"
        title="Delete all log entries for this email"
        @click.prevent.native="deleteLogs"
      >
        delete
      </mdc-icon>
    </div>
    <div v-else class="p-4 text-xl font-bold">Viewing most recent logs</div>
    <template v-if="logs">
      <div
        v-for="log in logs.data"
        :key="log.hash"
        :class="{
          'bg-white': log.severity === 'info',
          'bg-warning-light': log.severity === 'temporary',
          'bg-error-light': log.severity === 'permanent',
        }"
        class="sm:flex shadow mb-4 p-2"
      >
        <later-date
          v-model="log.created_at"
          class="w-24 mr-4 text-right mb-4"
        />
        <code class="flex-1 text-sm w-full">
          <div v-if="!$route.params.hash && log.email && log.email.hash">
            <strong>Email: </strong>
            <span
              v-if="log.email.hidden && log.email.status === 'scheduled'"
              v-text="log.email.subject || '(no subject)'"
            />
            <router-link
              v-else
              :to="`/email/${log.email.hash}`"
              v-text="log.email.subject || '(no subject)'"
            />
          </div>
          <div v-if="log.recipient">
            <strong>Recipient: </strong><span v-text="log.recipient" />
          </div>
          <div
            class="whitespace-pre-wrap break-words overflow-auto sm:max-h-40"
            v-html="log.description"
          />
        </code>
      </div>
      <later-pagination v-model="logs" module="logs" />
    </template>
  </div>
</template>

<script>
import LaterDate from '@/components/date.vue';
import LaterPagination from '@/components/pagination.vue';
import MdcIcon from '@/components/mdc/icon.vue';
import axios from '@/utils/xhr';

export default {
  components: {
    LaterDate,
    LaterPagination,
    MdcIcon,
  },

  data: () => ({
    logs: null,
    unsubscribe: null,
  }),

  mounted() {
    this.fetch();

    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type === 'logs/setView' || mutation.type === 'logs/nextPage')
        this.fetch();
    });
  },

  beforeDestroy() {
    this.unsubscribe();
  },

  methods: {
    fetch() {
      let severity = 'all';
      if (this.$store.state.logs.pagination.view === 'error')
        severity = 'permanent';
      else if (this.$store.state.logs.pagination.view === 'warning')
        severity = 'temporary';
      else if (this.$store.state.logs.pagination.view === 'success')
        severity = 'info';

      axios
        .post('/email/logs/results', {
          email: this.$route.params.hash,
          severity,
          page: this.$store.state.logs.pagination.page,
        })
        .then((response) => {
          this.logs = response.data;
          document.body.scrollTop = 0;
          if (this.$store.state.logs.pagination.view === 'all')
            this.$store.commit('logs/setTotal', response.data.total);
        });
    },

    deleteLogs() {
      axios.delete(`/email/logs/${this.$route.params.hash}`).then(() => {
        this.logs.data = [];
        this.$store.commit(
          'snackbarMessage',
          'Logs for this email have been deleted.'
        );
        this.$router.back();
      });
    },
  },
};
</script>
